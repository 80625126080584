<template>
  <div>
    <v-card flat>
      <v-toolbar dark flat height="52px" color="advisorLayout" class="mt-n16 ">
        <v-toolbar-title class="headline font-weight-normal  ml-10"
          ><div>
            {{ headline }}
          </div></v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-title class="headline font-weight-normal  mr-12">
          {{ userName }}
        </v-toolbar-title>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'theAdvisorLayoutHeader',

  props: {
    headline: { type: String }
  },

  data() {
    return {}
  },

  computed: {
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
