<template>
  <div>
    <the-advisor-layout-header headline="Dashboard" />

    <v-card width="880" class="ml-14 mt-6" outlined>
      <v-row>
        <v-col cols="6">
          <v-toolbar-title class="headline primary--text ml-4 mt-4"
            >My Client Accounts
          </v-toolbar-title>
        </v-col>

        <v-col cols="2">
          <v-card-text class="text-right"
            ><v-icon
              color="success"
              @click="clientNew"
              class="display-1 mr-n6 mt-1"
              >mdi-account-plus</v-icon
            >
          </v-card-text>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            label="search"
            outlined
            hide-details
            class="subtitle-2 primary--text mr-4 mt-4"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-n6">
          <v-tabs
            v-model="tabs"
            color="primary"
            slider-color="success"
            center-active
          >
            <v-tab
              v-for="i in 4"
              :key="i"
              :href="`#tab-${i}`"
              class="primary--text subtitle-2 mt-3"
            >
              {{ items[i - 1] }}
            </v-tab>
          </v-tabs>
          <v-divider class="success"></v-divider>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tabs">
        <v-tab-item value="tab-1" key="1">
          <template>
            <v-data-table
              :headers="headers"
              :items="myActiveClients"
              :search="search"
              :items-per-page="5"
              class="title primary--text"
            >
              <template v-slot:top>
                <v-card flat color="white">
                  <v-row>
                    <v-col cols="6"> </v-col>

                    <v-col cols="2"> </v-col>

                    <v-col cols="4"> </v-col>
                  </v-row>
                </v-card>
              </template>

              <template v-slot:item.name="{ item }">
                <v-btn
                  text
                  retain-focus-on-click
                  color="success"
                  @click="openItem(item)"
                  class="text-capitalize ml-n4"
                  >{{ item.name }}</v-btn
                >
              </template>

              <template v-slot:item.secondaryAdvisor="{ item }">
                <v-btn
                  text
                  retain-focus-on-click
                  color="success"
                  @click="editItemDialog1(item)"
                  class="text-capitalize ml-n4"
                  >{{ item.secondaryAdvisor }}</v-btn
                >
              </template>

              <template v-slot:item.accState="{ item }">
                <div v-if="item.accountState == 'active'">
                  <v-icon color="green" @click="editItemDialog2(item)"
                    >mdi-check</v-icon
                  >
                </div>
                <div v-else-if="item.accountState == 'blocked'">
                  <v-icon small color="red" @click="editItemDialog2(item)"
                    >mdi-do-not-disturb</v-icon
                  >
                </div>
                <div v-else-if="item.accountState == 'archived'">
                  <v-icon
                    color="success"
                    @click="editItemDialog2(item)"
                    class="title"
                  >
                    mdi-archive
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon
                    color="success"
                    @click="editItemDialog2(item)"
                    class="title"
                  >
                    mdi-delete-alert
                  </v-icon>
                </div>
              </template>

              <!-- <template v-slot:item.onlineAccess="{ item }">
                <div v-if="item.accountState == 'active'">
                  <v-icon>mdi-check</v-icon>
                </div>
                <div v-else>
                  <v-icon small>
                    mdi-do-not-disturb
                  </v-icon>
                </div>
              </template> -->

              <template v-slot:no-data>
                <v-card flat>
                  <v-card-text class="title font-weight-regular">
                    You have no Clients yet, let's get started ...
                    <v-icon
                      color="success"
                      class="mb-4 display-1"
                      @click="clientNew"
                      >mdi-account-plus</v-icon
                    >
                  </v-card-text>
                </v-card>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>

        <v-tab-item value="tab-2" key="2">
          <template>
            <v-data-table
              :headers="headersArchDel"
              :items="myArchivedClients"
              :search="search"
              :items-per-page="5"
              class="title primary--text"
            >
              <template v-slot:top>
                <v-card flat color="white">
                  <v-row>
                    <v-col cols="6"> </v-col>

                    <v-col cols="2"> </v-col>

                    <v-col cols="4"> </v-col>
                  </v-row>
                </v-card>
              </template>

              <template v-slot:item.name="{ item }">
                <v-btn
                  text
                  retain-focus-on-click
                  color="success"
                  @click="openItem(item)"
                  class="text-capitalize ml-n4"
                  >{{ item.name }}</v-btn
                >
              </template>

              <template v-slot:item.accState="{ item }">
                <div
                  v-if="
                    item.accountState == 'active' ||
                      item.accountState == 'blocked'
                  "
                >
                  <v-icon color="success" @click="editItemDialog2(item)"
                    >mdi-check</v-icon
                  >
                </div>
                <div v-else-if="item.accountState == 'archived'">
                  <v-icon
                    color="success"
                    @click="editItemDialog2(item)"
                    class="title"
                  >
                    mdi-archive
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon
                    color="primary"
                    @click="editItemDialog2(item)"
                    class="title"
                  >
                    mdi-delete-alert
                  </v-icon>
                </div>
              </template>

              <template v-slot:no-data>
                <v-card flat>
                  <v-card-text class="title font-weight-regular">
                    You have no archived Clients yet ...
                  </v-card-text>
                </v-card>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>

        <v-tab-item value="tab-3" key="3">
          <template>
            <v-data-table
              :headers="headersArchDel"
              :items="myDeletedClients"
              :search="search"
              :items-per-page="5"
              class="title primary--text"
            >
              <template v-slot:top>
                <v-card flat color="white">
                  <v-row>
                    <v-col cols="6"> </v-col>

                    <v-col cols="2"> </v-col>

                    <v-col cols="4"> </v-col>
                  </v-row>
                </v-card>
              </template>

              <template v-slot:item.name="{ item }">
                <v-btn
                  text
                  retain-focus-on-click
                  color="success"
                  @click="openItem(item)"
                  class="text-capitalize ml-n4"
                  >{{ item.name }}</v-btn
                >
              </template>

              <template v-slot:item.accState="{ item }">
                <div
                  v-if="
                    item.accountState == 'active' ||
                      item.accountState == 'blocked'
                  "
                >
                  <v-icon color="success" @click="editItemDialog2(item)"
                    >mdi-check</v-icon
                  >
                </div>
                <div v-else-if="item.accountState == 'archived'">
                  <v-icon
                    color="success"
                    @click="editItemDialog2(item)"
                    class="title"
                  >
                    mdi-archive
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon
                    color="success"
                    @click="editItemDialog2(item)"
                    class="title"
                  >
                    mdi-delete-alert
                  </v-icon>
                </div>
              </template>

              <template v-slot:no-data>
                <v-card flat>
                  <v-card-text class="title font-weight-regular">
                    You have no deleted Clients yet ...
                  </v-card-text>
                </v-card>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>

        <v-tab-item value="tab-4" key="4">
          <template>
            <v-data-table
              :headers="headersStandIn"
              :items="myStandInClients"
              :search="search"
              :items-per-page="5"
              class="title primary--text"
            >
              <template v-slot:top>
                <v-card flat color="white">
                  <v-row>
                    <v-col cols="6"> </v-col>

                    <v-col cols="2"> </v-col>

                    <v-col cols="4"> </v-col>
                  </v-row>
                </v-card>
              </template>

              <template v-slot:item.name="{ item }">
                <v-btn
                  text
                  retain-focus-on-click
                  color="success"
                  @click="openItem(item)"
                  class="text-capitalize ml-n4"
                  >{{ item.name }}</v-btn
                >
              </template>

              <template v-slot:item.accState="{ item }">
                <div v-if="item.accountState == 'active'">
                  <v-icon color="green" @click="editItemDialog4(item)"
                    >mdi-check</v-icon
                  >
                </div>
                <div v-else-if="item.accountState == 'blocked'">
                  <v-icon small color="red" @click="editItemDialog4(item)"
                    >mdi-do-not-disturb</v-icon
                  >
                </div>
                <div v-else-if="item.accountState == 'archived'">
                  <v-icon
                    color="success"
                    @click="editItemDialog4(item)"
                    class="title"
                  >
                    mdi-archive
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon
                    color="success"
                    @click="editItemDialog4(item)"
                    class="title"
                  >
                    mdi-delete-alert
                  </v-icon>
                </div>
              </template>

              <!-- <template v-slot:item.onlineAccess="{ item }">
                <div v-if="item.accountState == 'active'">
                  <v-icon>mdi-check</v-icon>
                </div>
                <div v-else>
                  <v-icon small>
                    mdi-do-not-disturb
                  </v-icon>
                </div>
              </template> -->

              <template v-slot:no-data>
                <v-card flat>
                  <v-card-text class="title font-weight-regular">
                    Relax! You have no stand-in clients from other advisors yet
                    ...
                  </v-card-text>
                </v-card>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog persistent v-model="dialog1" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline primary--text">Account Settings</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="subtitle-1 primary--text ml-n4 mt-n8">
                    {{ clientProfile.name }}
                  </v-card-text>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="clientProfile.secondaryAdvisor"
                    :items="myStandInAdvisors"
                    label="Stand-In Advisor"
                    dense
                    class="primary--text mt-n6"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text class="primary--text mb-n2" @click="close"
                >Cancel</v-btn
              >
              <v-btn text class="mr-n2 mb-n2" color="success" @click="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialog2" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline primary--text">Account Settings</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="subtitle-1 primary--text ml-n4 mt-n8">
                    {{ clientProfile.firstName }}
                    {{ clientProfile.familyName }}
                  </v-card-text>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="clientProfile.accountState"
                    :items="accountStateItems"
                    label="Account State"
                    dense
                    class="primary--text mt-n6"
                  ></v-select>
                </v-col>
                <v-col cols="12"> </v-col>
                <v-col cols="12"> </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text class="primary--text mb-n2" @click="close"
                >Cancel</v-btn
              >
              <v-btn text class="mr-n2 mb-n2" color="success" @click="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialog4" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline primary--text">Account Settings</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="subtitle-1 primary--text ml-n4 mt-n8">
                    {{ clientProfile.firstName }}
                    {{ clientProfile.familyName }}
                  </v-card-text>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="clientProfile.accountState"
                    :items="accountStateItemsStandIn"
                    label="Account State"
                    dense
                    class="primary--text mt-n6"
                  ></v-select>
                </v-col>
                <v-col cols="12"> </v-col>
                <v-col cols="12"> </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text class="primary--text mb-n2" @click="close"
                >Cancel</v-btn
              >
              <v-btn text class="mr-n2 mb-n2" color="success" @click="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { authComputed } from '@/vuex/helpers.js'
import TheAdvisorLayoutHeader from '@/components/TheAdvisorLayoutHeader.vue'

export default {
  name: 'advisorDashboard',

  // Navigation Route Guard to preload API Data before page is being shown
  beforeRouteEnter(routeTo, routeFrom, next) {
    store.dispatch('fetchClients').then(() => {
      // making sure, when navigating to this page, the slider allways moves to "DASHBOARD" ( tab = 0 )
      store.commit('SET_ADVISOR_LAYOUT_TAB', 0)
      next()
    })
  },

  components: {
    TheAdvisorLayoutHeader
  },

  data() {
    return {
      tabs: null,
      search: '',
      dialog1: false,
      dialog2: false,
      dialog4: false,
      headers: [
        {
          text: 'Client Name / Account',
          align: 'left',
          value: 'name',
          class: 'subtitle-2 primary--text'
        },
        {
          text: 'last edit',
          align: 'left',
          value: 'modified',
          class: 'subtitle-2 primary--text pl-6'
        },
        {
          text: 'Stand-In Advisor',
          align: 'center',
          value: 'secondaryAdvisor',
          width: 230,
          class: 'subtitle-2 primary--text'
        },
        {
          text: 'Online Access',
          align: 'center',
          value: 'accState',
          sortable: false,
          class: 'subtitle-2 primary--text'
        }
        // {
        //   text: 'Access',
        //   align: 'center',
        //   value: 'onlineAccess',
        //   sortable: false,
        //   class: 'subtitle-1 primary--text'
        // }
      ],
      headersArchDel: [
        {
          text: 'Client Name / Account',
          align: 'left',
          value: 'name',
          class: 'subtitle-2 primary--text'
        },
        {
          text: 'last edit',
          align: 'left',
          value: 'modified',
          class: 'subtitle-2 primary--text pl-6'
        },
        {
          text: 'State',
          align: 'center',
          value: 'accState',
          sortable: false,
          class: 'subtitle-2 primary--text'
        }
      ],
      headersStandIn: [
        {
          text: 'Client Name / Account',
          align: 'left',
          value: 'name',
          width: 300,
          class: 'subtitle-2 primary--text'
        },
        {
          text: 'last edit',
          align: 'left',
          value: 'modified',
          class: 'subtitle-2 primary--text pl-6'
        },
        {
          text: 'Advisor',
          align: 'center',
          value: 'primaryAdvisor',
          width: 200,
          class: 'subtitle-2 primary--text'
        },
        {
          text: 'Online Access',
          align: 'center',
          value: 'accState',
          sortable: false,
          class: 'subtitle-2 primary--text'
        }
        // {
        //   text: 'Access',
        //   align: 'center',
        //   value: 'onlineAccess',
        //   sortable: false,
        //   class: 'subtitle-1 primary--text'
        // }
      ],
      stateItems: {
        active: [
          {
            text: 'active - online access',
            value: 'active'
          },
          {
            text: 'active - no online access',
            value: 'blocked'
          }
        ],
        blocked: [
          { text: 'active - online access', value: 'active' },
          {
            text: 'active - no online access',
            value: 'blocked'
          },
          {
            text: 'inactive - archived',
            value: 'archived'
          }
        ],
        archived: [
          {
            text: 'active - no online access',
            value: 'blocked'
          },
          {
            text: 'inactive - archived',
            value: 'archived'
          },
          {
            text: 'inactive - deleted',
            value: 'deleted'
          }
        ],
        deleted: [
          {
            text: 'inactive - archived',
            value: 'archived'
          },
          {
            text: 'inactive - deleted',
            value: 'deleted'
          }
        ]
      },
      stateItemsStandIn: {
        active: [
          {
            text: 'active - online access',
            value: 'active'
          },
          {
            text: 'active - no online access',
            value: 'blocked'
          }
        ],
        blocked: [
          { text: 'active - online access', value: 'active' },
          {
            text: 'active - no online access',
            value: 'blocked'
          }
        ]
      }
    }
  },
  methods: {
    openItem(item) {
      console.log('Open Client', item)
      this.$store.dispatch('fetchClientGlobal', item.sortKey).then(() => {
        this.$router.push('/client/dashboard')
      })
    },
    clientNew() {
      this.$router.push('/advisor/client-new')
    },
    editItemDialog1(item) {
      console.log('Edit Settings', item)
      this.$store.dispatch('fetchClient', item.sortKey).then(() => {
        this.dialog1 = true
      })
    },
    editItemDialog2(item) {
      console.log('Edit Settings', item)
      this.$store.dispatch('fetchClient', item.sortKey).then(() => {
        this.dialog2 = true
      })
    },
    editItemDialog4(item) {
      console.log('Edit Settings', item)
      this.$store.dispatch('fetchClient', item.sortKey).then(() => {
        this.dialog4 = true
      })
    },
    close() {
      this.$store.dispatch('fetchClient', this.clientId).then(() => {
        this.dialog1 = false
        this.dialog2 = false
        this.dialog4 = false
      })
    },
    save() {
      this.$store.dispatch('updateClientSettings', this.clientId).then(() => {
        this.dialog1 = false
        this.dialog2 = false
        this.dialog4 = false
      })
    }
  },
  computed: {
    accountStateItems() {
      const state = this.clientProfile.accountState
      return this.stateItems[state]
    },
    accountStateItemsStandIn() {
      const stateStandIn = this.clientProfile.accountState
      return this.stateItemsStandIn[stateStandIn]
    },
    items() {
      let tabItems = [
        'Active ' + '(' + this.$store.getters.myActiveClientsCount + ')',

        'Archived ' + '(' + this.$store.getters.myArchivedClientsCount + ')',

        'Deleted ' + '(' + this.$store.getters.myDeletedClientsCount + ')',

        'Stand-Ins ' + '(' + this.$store.getters.myStandInClientsCount + ')'
      ]
      return tabItems
    },
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
